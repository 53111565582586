<template lang="html">
	<div class="container-fluid p-5">
		<loading :active.sync="isLoading" :is-full-page="fullPage"></loading>
		<div class="row">
			<div class="col-md-12">
				<div class="card">
					<div class="card-header">
						<h6><a href="javascript:void(0)" class="text-info" @click="back()"> <i class="fas fa-arrow-left"></i></a> New product</h6>
					</div>
					<div class="card-body">
						<form v-on:submit.prevent="onSubmit">

							<div class="form-group row">
								<label for="inputCategory" class="col-sm-2 col-form-label">Category <span class="text-danger">*</span></label>
								<div class="col-sm-10">
									<select class="form-control" v-model="productRequest.categoryId" @change="validationRule()">
										<option value="">Choose Category</option>
										<option v-for="category in categoryList" :value="category.id">{{category.name}}</option>
									</select>
									<small class="text-danger">{{validationData.iconCategoryId}}</small>
								</div>
							</div>

							<div class="form-group row">
								<label for="inputName" class="col-sm-2 col-form-label">Name <span class="text-danger">*</span></label>
								<div class="col-sm-10">
									<input type="text" v-model="productRequest.name" @blur="validationRule()"
										class="form-control" id="inputName">
									<small class="text-danger">{{validationData.name}}</small>
								</div>
							</div>

							<div class="form-group row">
								<label for="inputPrice" class="col-sm-2 col-form-label">Price<span class="text-danger">*</span></label>
								<div class="col-sm-10">
									<input type="text" v-model="productRequest.price" @blur="validationRule()"
										class="form-control" id="inputPrice">
									<small class="text-danger">{{validationData.price}}</small>
								</div>
							</div>

							<div class="form-group row">
								<label for="inputDiscountPrice" class="col-sm-2 col-form-label">Discount Price</label>
								<div class="col-sm-10">
									<input type="text" v-model="productRequest.discountPrice" @blur="validationRule()"
										class="form-control" id="inputDiscountPrice">
									<small class="text-danger">{{validationData.discountPrice}}</small>
								</div>
							</div>

							<div class="form-group row">
								<label for="inputWeight" class="col-sm-2 col-form-label">Weight</label>
								<div class="col-sm-10">
									<input type="text" v-model="productRequest.weight" @blur="validationRule()"
										class="form-control" id="inputWeight">
									<small class="text-danger">{{validationData.weight}}</small>
								</div>
							</div>

							<div class="form-group row">
								<label for="inputdescription" class="col-sm-2 col-form-label">Description <span class="text-danger">*</span></label>
								<div class="col-sm-10">
									<vue-editor id="editor1" v-model="productRequest.description" :editorToolbar="customToolbar" @blur="validationRule()"></vue-editor>
								</div>
							</div>

							<div class="form-group row">
								<label for="inputImage" class="col-sm-2 col-form-label">Image <span class="text-danger">*</span></label>
								<div class="col-sm-10">
									<input type="file" class="form-control" id="inputImage" @change="readImage">
									<img v-if="productRequest.bannerImage" v-bind:src="bannerImage" class="banner-image mt-2"/>
									<small class="text-danger">{{validationData.bannerImage}}</small>
								</div>
							</div>

							<div class="form-group row">
								<label for="inputImage" class="col-sm-2 col-form-label">Card Image <span class="text-danger">*</span></label>
								<div class="col-sm-10">
									<input type="file" class="form-control" id="inputImage" @change="readImage1">
									<img v-if="productRequest.fullImage" v-bind:src="fullImage" class="banner-image mt-2"/>
									<small class="text-danger">{{validationData.fullImage}}</small>
								</div>
							</div>

							<div class="form-group row">
								<label for="inputHotdels" class="col-sm-2 col-form-label"></label>
								<div class="col-sm-10">
									<div class="form-check">
										<div>
											<input type="checkbox" class="form-check-input" v-model="productRequest.customizeStatus" true-value="1" false-value="0">
											<label for="inputHotdels" class="form-check-label">Customize Status </label>
										</div>
									</div>
								</div>
							</div>

							<div class="form-group row">
								<label for="inputHotdels" class="col-sm-2 col-form-label"></label>
								<div class="col-sm-10">
									<div class="form-check">
										<div>
											<input type="checkbox" class="form-check-input" v-model="productRequest.status" true-value="1" false-value="0">
											<label for="inputHotdels" class="form-check-label">Status </label>
										</div>
									</div>
								</div>
							</div>

							<button type="submit" class="btn btn-primary float-save">Submit</button>
						</form>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script lang="js">
	import {
		mapState,
		mapActions
	} from 'vuex'
	import env from '../../enviorments'
	import Loading from 'vue-loading-overlay'
	import 'vue-loading-overlay/dist/vue-loading.css';
	export default {
		name: 'productCreate',
		metaInfo: {
            title: "Product Create",
            titleTemplate: "%s ← SHRE Dashboard",
        },
		components: {
			Loading
		},
		data() {
			return {
				env,
				isLoading: false,
				fullPage: true,
				currentImage: "",
				categoryList: [],
				bannerImage: null,
				fullImage: null,
				productRequest: {
					categoryId: "",
					name: "",
					price: "",
					discountPrice: "",
					weight: "",
					description: "",
					bannerImage: "",
					fullImage: "",
					customizeStatus: "",
					status: ""
				},
				validationData: {
					name: "",
					price: "",
					categoryId: "",
					description: "",
					bannerImage: "",
					fullImage: ""
				}
			}
		},
		watch: {
			async $route(to, from) {
			},
		},
		computed: {},
		methods: {
			...mapActions({
				productStoreAction: 'productStoreAction',
				productDetailAction: 'productDetailAction'
			}),
			readImage(event) {
				this.productRequest.bannerImage = event.target.files[0];

				//For Show
				const files = event.target.files[0]
				const fileReader = new FileReader()
				fileReader.readAsDataURL(files)
				fileReader.onload = e => {
					this.bannerImage = e.target.result
					this.validationRule()
				};
			},
			readImage1(event) {
				this.productRequest.fullImage = event.target.files[0];

				//For Show
				const files = event.target.files[0]
				const fileReader = new FileReader()
				fileReader.readAsDataURL(files)
				fileReader.onload = e => {
					this.fullImage = e.target.result
					this.validationRule()
				};
			},
			async getFields(){
				let option = {
					type: "store"
				}
				await this.productDetailAction({
					...option
				}).then(res => {
					this.categoryList = res.data.category
					this.isLoading = false
				}).catch(err => this.isLoading = true)
			},
			async onSubmit() {
				this.isLoading = true
				let validation = this.validationRule()
				if (validation == true) {
					this.isLoading = false
					return
				}
				const formData = new FormData();
                formData.append('categoryId', this.productRequest.categoryId);
				formData.append('name', this.productRequest.name); 
				formData.append('price', this.productRequest.price); 
				formData.append('discountPrice', this.productRequest.discountPrice); 
				formData.append('weight', this.productRequest.weight); 
				formData.append('description', this.productRequest.description); 
				formData.append('bannerImage', this.productRequest.bannerImage); 
				formData.append('fullImage', this.productRequest.fullImage); 
				formData.append('customizeStatus', this.productRequest.customizeStatus); 
				formData.append('status', this.productRequest.status); 
				
				await this.productStoreAction(formData).then(res => {
					this.back()
					this.isLoading = false;
				}).catch(err => this.isLoading = true)
			},
			back() {
				this.$router.replace({ path: 'product-list', query: {page: 1, orderBy: 'desc', sortKey: 'created_at', name: '',  price: '', description: ''} }).catch(()=>{})
			},
			validationRule() {
				let isValidation = []
				if (this.productRequest.name == "") {
					this.validationData.name = "The name field is required."
					isValidation.push(true)
				} else {
					this.validationData.name = ""
					isValidation.push(false)
				}
				if (this.productRequest.price == "") {
					this.validationData.price = "The price field is required."
					isValidation.push(true)
				} else {
					this.validationData.price = ""
					isValidation.push(false)
				}
				if (this.productRequest.categoryId == "") {
					this.validationData.categoryId = "The category field is required."
					isValidation.push(true)
				} else {
					this.validationData.categoryId = ""
					isValidation.push(false)
				}
				if (this.productRequest.description == "") {
					this.validationData.description = "The description field is required."
					isValidation.push(true)
				} else {
					this.validationData.description = ""
					isValidation.push(false)
				}
				if (this.productRequest.bannerImage == "") {
					this.validationData.bannerImage = "The image field is required."
					isValidation.push(true)
				} else {
					this.validationData.bannerImage = ""
					isValidation.push(false)
				}
				if (this.productRequest.fullImage == "") {
					this.validationData.fullImage = "The image field is required."
					isValidation.push(true)
				} else {
					this.validationData.fullImage = ""
					isValidation.push(false)
				}
				if (isValidation.includes(true)) {
					return true
				} else {
					return false
				}
			}
		},
		async mounted() {
			this.getFields()
		}
	}
</script>

<style scoped lang="scss">
	.v-text-field--box .v-input__slot,
	.v-text-field--outline .v-input__slot {
		min-height: 56px;
	}
	.banner-image {
		width: 100px;
		height: 100px;
		object-fit: contain;
	}
</style>